@import '../../style/themes/index';
@import '../../style/mixins/index';

@calendar-prefix-cls: ~'@{ant-prefix}-picker-calendar';
@calendar-picker-prefix-cls: ~'@{ant-prefix}-picker';

.@{calendar-prefix-cls} {
  .reset-component();
  background: @calendar-full-bg;

  // ========================= Header =========================
  &-header {
    display: flex;
    justify-content: flex-end;
    padding: @padding-sm 0;

    .@{calendar-prefix-cls}-year-select {
      min-width: 80px;
    }

    .@{calendar-prefix-cls}-month-select {
      min-width: 70px;
      margin-left: @padding-xs;
    }

    .@{calendar-prefix-cls}-mode-switch {
      margin-left: @padding-xs;
    }
  }

  .@{calendar-picker-prefix-cls}-panel {
    background: @calendar-full-panel-bg;
    border: 0;
    border-top: @border-width-base @border-style-base @border-color-split;
    border-radius: 0;

    .@{calendar-picker-prefix-cls}-month-panel,
    .@{calendar-picker-prefix-cls}-date-panel {
      width: auto;
    }

    .@{calendar-picker-prefix-cls}-body {
      padding: @padding-xs 0;
    }

    .@{calendar-picker-prefix-cls}-content {
      width: 100%;
    }
  }

  // ========================== Mini ==========================
  &-mini {
    border-radius: @border-radius-base;

    .@{calendar-picker-prefix-cls}-calendar-header {
      padding-right: @padding-xs;
      padding-left: @padding-xs;
    }

    .@{calendar-picker-prefix-cls}-panel {
      border-radius: 0 0 @border-radius-base @border-radius-base;
    }

    .@{calendar-picker-prefix-cls}-content {
      height: 256px;

      th {
        height: auto;
        padding: 0;
        line-height: 18px;
      }
    }
  }

  // ========================== Full ==========================
  &-full {
    .@{calendar-picker-prefix-cls}-panel {
      display: block;
      width: 100%;
      text-align: right;
      background: @calendar-full-bg;
      border: 0;

      .@{calendar-picker-prefix-cls}-body {
        th,
        td {
          padding: 0;
        }

        th {
          height: auto;
          padding: 0 12px 5px 0;
          line-height: 18px;
        }
      }

      // Cell
      .@{calendar-picker-prefix-cls}-cell {
        &::before {
          display: none;
        }

        &:hover {
          .@{calendar-prefix-cls}-date {
            background: @item-hover-bg;
          }
        }

        .@{calendar-prefix-cls}-date-today::before {
          display: none;
        }

        &-selected,
        &-selected:hover {
          .@{calendar-prefix-cls}-date,
          .@{calendar-prefix-cls}-date-today {
            background: @calendar-item-active-bg;

            .@{calendar-prefix-cls}-date-value {
              color: @primary-color;
            }
          }
        }
      }

      // Cell date
      .@{calendar-prefix-cls}-date {
        display: block;
        width: auto;
        height: auto;
        margin: 0 (@padding-xs / 2);
        padding: (@padding-xs / 2) @padding-xs 0;
        border: 0;
        border-top: 2px solid @border-color-split;
        border-radius: 0;
        transition: background 0.3s;

        &-value {
          line-height: 24px;
          transition: color 0.3s;
        }

        &-content {
          position: static;
          width: auto;
          height: 86px;
          overflow-y: auto;
          color: @text-color;
          line-height: @line-height-base;
          text-align: left;
        }

        &-today {
          border-color: @primary-color;

          .@{calendar-prefix-cls}-date-value {
            color: @text-color;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: @screen-xs) {
  .@{calendar-prefix-cls} {
    &-header {
      display: block;

      .@{calendar-prefix-cls}-year-select {
        width: 50%;
      }

      .@{calendar-prefix-cls}-month-select {
        width: ~'calc(50% - @{padding-xs})';
      }

      .@{calendar-prefix-cls}-mode-switch {
        width: 100%;
        margin-top: @padding-xs;
        margin-left: 0;

        > label {
          width: 50%;
          text-align: center;
        }
      }
    }
  }
}

@import './rtl';

@primary-color: #30597e;@layout-body-background: #fff;